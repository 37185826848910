<template>
  <main-layout>
    <div slot="content-body">
      <div class="vms-project-new__container--background">
        <div class="vms-project-new__container">
          <div class="vd-padding-20 vd-background-white">
            <div class="vd-h2 vd-light-blue vd-text-align-center">
              New Project
            </div>
            <div
              class="vd-h5 vd-black vd-text-align-center vd-padding-top-medium vd-padding-bottom-medium"
            >
              Let's start with selecting a contact
            </div>
            <div class="vd-text-align-center">
              <ais-instant-search
                index-name="user_offices_index"
                :search-client="searchClient"
                :middlewares="middlewares"
              >
                <ais-configure :hits-per-page.camel="4" />

                <ais-search-box>
                  <contact-search-box
                    slot-scope="{ currentRefinement, refine }"
                    :current-refinement="currentRefinement"
                    @update:current-refinement="
                      refine($event.currentTarget.value)
                    "
                  ></contact-search-box>
                </ais-search-box>

                <ais-state-results class="vms-project-new__results">
                  <template slot-scope="{ query, hits }">
                    <result-hits-empty
                      v-if="hits.length === 0"
                      class="vms-project-new__contents vd-background-white vd-border-light-grey vd-text-align-center vd-padding-top-small"
                      :query="query"
                    ></result-hits-empty>

                    <ais-hits v-else-if="query.length > 0">
                      <result-hits
                        slot-scope="{ items }"
                        :items="items"
                        class="vms-project-new__contents vd-background-white vd-border-light-grey vd-text-align-left"
                        @handle:contact-selected="
                          selectedContactSendEvent($event)
                        "
                      ></result-hits>
                    </ais-hits>

                    <div v-else class="vms-project-new__contents"></div>
                  </template>
                </ais-state-results>
              </ais-instant-search>
            </div>

            <div
              class="vd-h5 vd-black vd-text-align-right vd-padding-top-medium"
            >
              <a
                id="backToProjectsListLink"
                :href="vmsProjectUrl"
                class="vd-h5 vd-dark-grey"
              >
                Back to projects list
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
import algoliaSearch from '@plugins/algolia'
import {
  AisInstantSearch,
  AisSearchBox,
  AisHits,
  AisStateResults,
  AisConfigure,
} from 'vue-instantsearch'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import algoliaAnalytics from 'search-insights'
import ContactSearchBox from '@views/projects/new-project/components/contact-search-box'
import ResultHits from '@views/projects/new-project/components/result-hits'
import ResultHitsEmpty from '@views/projects/new-project/components/no-results'
import appConfig from '@configs/app-config'
import { mapGetters } from 'vuex'

// Don't initialise analytics testing env (jest).
if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

// Do not create insights client for test env.
const algoliaAnalyticsMiddleware = createInsightsMiddleware({
  insightsClient: process.env.NODE_ENV !== 'test' ? algoliaAnalytics : null,
})

export default {
  components: {
    MainLayout,
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    AisStateResults,
    AisConfigure,
    ContactSearchBox,
    ResultHits,
    ResultHitsEmpty,
  },

  data() {
    return {
      searchClient: algoliaSearch,
      middlewares: [algoliaAnalyticsMiddleware],
    }
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),

    vmsProjectUrl() {
      return `${appConfig.vmsApiUrl}/projects`
    },
  },

  watch: {
    'loggedInUser.id': (val) => {
      if (val) {
        algoliaAnalytics('setUserToken', val.toString())
      }
    },
  },

  methods: {
    selectedContactSendEvent(objectId) {
      algoliaAnalytics('convertedObjectIDs', {
        eventName: 'Item Selected',
        index: 'user_offices_index',
        objectIDs: [objectId],
        userToken: this.loggedInUser.id.toString(),
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/projects/new-project';
</style>
