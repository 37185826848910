<template>
  <div>
    <p>
      No results found matching
      <strong>
        <em> {{ query }} </em>
      </strong>
      .
    </p>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>
