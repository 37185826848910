<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <div v-for="(office, officeIndex) in item.offices" :key="officeIndex">
        <div
          class="vms-project-new__contents--list vd-padding-top-10 vd-padding-bottom-10 vd-border-light-grey"
        >
          <div
            class="vms-project-new__contents--list__row vd-cursor-pointer vd-padding-left-medium"
            @click="updateProjectDetailsAndRedirect(item, office)"
          >
            <div class="vd-black vd-h4">
              <div class="vms-project-new__contents--list__icon">
                <div
                  class="vd-icon-user-circle-grey vms-project-new__contents--list__row--icon-user"
                ></div>
              </div>
              <div class="vms-project-new__contents--list__text">
                <ais-highlight
                  attribute="full_name"
                  :hit="item"
                  highlighted-tag-name="strong"
                  :class-names="{
                    'ais-Highlight': 'vd-padding-left-medium',
                    'ais-Highlight-highlighted': 'vd-background-aqua ',
                  }"
                />
              </div>
            </div>
            <div class="vd-black vd-h4">
              <div class="vms-project-new__contents--list__icon">
                <div
                  class="vd-icon-building vms-project-new__contents--list__row--icon-company"
                ></div>
              </div>
              <div class="vms-project-new__contents--list__text">
                <span class="vd-padding-left-medium">
                  {{ office.company_name }}
                </span>
              </div>
            </div>
            <div class="vd-black vd-h4">
              <div class="vms-project-new__contents--list__icon">
                <div
                  class="vd-icon-chair-office vms-project-new__contents--list__row--icon-office"
                ></div>
              </div>
              <div class="vms-project-new__contents--list__text">
                <span class="vd-padding-left-medium">
                  {{ office.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { AisHighlight } from 'vue-instantsearch'

export default {
  components: {
    AisHighlight,
  },

  props: {
    items: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  methods: {
    ...mapActions({
      updateNewProjectDetailsFromAlgolia:
        'project/updateNewProjectDetailsFromAlgolia',
    }),

    updateProjectDetailsAndRedirect(user, office) {
      let actionPayload = user

      actionPayload.user_id = parseInt(user.user_id)
      actionPayload.office = office

      this.updateNewProjectDetailsFromAlgolia(actionPayload)
      this.$emit('handle:contact-selected', user.objectID)
    },
  },
}
</script>
