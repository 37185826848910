var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.user.photo_url && _vm.canUseUserPhoto)?_c('img',{class:['vd-border-radius-100', _vm.customImageClass],attrs:{"src":_vm.user.photo_url,"width":_vm.customAvatarFontSize,"height":_vm.customAvatarFontSize}}):_c('span',{class:[_vm.customAvatarClass],domProps:{"innerHTML":_vm._s(
      _vm.generateAvatarSvgFromFullName(
        _vm.user,
        _vm.customAvatarBgColour,
        _vm.customAvatarFontSize,
        _vm.customAvatarTextColour
      )
    )}})])}
var staticRenderFns = []

export { render, staticRenderFns }