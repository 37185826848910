var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',[_c('div',{attrs:{"slot":"content-body"},slot:"content-body"},[_c('div',{staticClass:"vms-project-new__container--background"},[_c('div',{staticClass:"vms-project-new__container"},[_c('div',{staticClass:"vd-padding-20 vd-background-white"},[_c('div',{staticClass:"vd-h2 vd-light-blue vd-text-align-center"},[_vm._v(" New Project ")]),_c('div',{staticClass:"vd-h5 vd-black vd-text-align-center vd-padding-top-medium vd-padding-bottom-medium"},[_vm._v(" Let's start with selecting a contact ")]),_c('div',{staticClass:"vd-text-align-center"},[_c('ais-instant-search',{attrs:{"index-name":"user_offices_index","search-client":_vm.searchClient,"middlewares":_vm.middlewares}},[_c('ais-configure',{attrs:{"hitsPerPage":4}}),_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return _c('contact-search-box',{attrs:{"current-refinement":currentRefinement},on:{"update:current-refinement":function($event){return refine($event.currentTarget.value)}}})}}])}),_c('ais-state-results',{staticClass:"vms-project-new__results",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
var hits = ref.hits;
return [(hits.length === 0)?_c('result-hits-empty',{staticClass:"vms-project-new__contents vd-background-white vd-border-light-grey vd-text-align-center vd-padding-top-small",attrs:{"query":query}}):(query.length > 0)?_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _c('result-hits',{staticClass:"vms-project-new__contents vd-background-white vd-border-light-grey vd-text-align-left",attrs:{"items":items},on:{"handle:contact-selected":function($event){return _vm.selectedContactSendEvent($event)}}})}}],null,true)}):_c('div',{staticClass:"vms-project-new__contents"})]}}])})],1)],1),_c('div',{staticClass:"vd-h5 vd-black vd-text-align-right vd-padding-top-medium"},[_c('a',{staticClass:"vd-h5 vd-dark-grey",attrs:{"id":"backToProjectsListLink","href":_vm.vmsProjectUrl}},[_vm._v(" Back to projects list ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }