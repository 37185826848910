import { split, upperCase, capitalize, size, random } from 'lodash'
import UIAvatarSvg from 'ui-avatar-svg'
import colours from '@configs/colours'

export default {
  methods: {
    /**
     * Create an initials avatar using the UIAvatarSvg plugin
     *
     * @param {Object} user - The user object
     * @param {String} bgColour - Optional. If provided, this will be used as the avatar background colour,
     * otherwise we will use the user's calendar colour if available.
     * @param {Number} size - Optional. If provided, this will set the size of the avatar. Default is 45
     * @param textColour - Optional. If provided, this will be used for the initials text colour. Default is #fff
     * @param fontFamily - Optional. If provided, this will set the font family of the initials text. Default is Lato
     */
    generateAvatarSvgFromFullName(user, bgColour, fontSize, textColour) {
      // Split the provided name by spaces.
      const fullName = user.full_name || `${user.first_name} ${user.last_name}`
      const namesArr = split(fullName, ' ', 2)

      // Create the initials in uppercase or capitalise the first chars from first name.
      const letters =
        size(namesArr) === 1
          ? capitalize(namesArr[0].slice(0, 2))
          : upperCase(`${namesArr[0].slice(0, 1)}${namesArr[1].slice(0, 1)}`)

      // Select a random index the array of available colours.
      const colourIndex = random(0, colours.avatarColours.length)

      // Set avatar background colour
      const avatarBgColour =
        bgColour || user.calendar_color || colours.avatarColours[colourIndex]

      // Create the letters avatar using the plugin.
      return new UIAvatarSvg()
        .text(letters)
        .size(fontSize)
        .bgColor(avatarBgColour)
        .textColor(textColour)
        .fontFamily('Lato')
        .generate()
    },
  },
}
