<template>
  <span>
    <img
      v-if="user.photo_url && canUseUserPhoto"
      :src="user.photo_url"
      :width="customAvatarFontSize"
      :height="customAvatarFontSize"
      :class="['vd-border-radius-100', customImageClass]"
    />
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-else
      :class="[customAvatarClass]"
      v-html="
        generateAvatarSvgFromFullName(
          user,
          customAvatarBgColour,
          customAvatarFontSize,
          customAvatarTextColour
        )
      "
    ></span>
  </span>
</template>

<script>
import UserProfile from '@mixins/user-profile-mixin'

export default {
  mixins: [UserProfile],

  props: {
    user: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    customImageClass: {
      type: String,
      required: false,
      default: '',
    },

    customAvatarClass: {
      type: String,
      required: false,
      default: '',
    },

    customAvatarBgColour: {
      type: String,
      required: false,
      default: '',
    },

    customAvatarFontSize: {
      type: Number,
      required: false,
      default: 40,
    },

    customAvatarTextColour: {
      type: String,
      required: false,
      default: '#fff',
    },

    canUseUserPhoto: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
