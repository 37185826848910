<template>
  <div class="vd-position-relative">
    <div
      class="vd-icon-search vms-search-box__icon vd-margin-left-large"
      @click="handleSearchIconClick"
    ></div>
    <input
      id="searchContactForNewProjectInput"
      ref="searchContactForNewProjectInput"
      type="search"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      maxlength="512"
      autofocus="true"
      :placeholder="searchPlaceholder"
      :value="currentRefinement"
      :class="[
        'vms-search-box__input vd-border-light-grey vd-border-radius-6 vd-padding-large vd-padding-right-0',
        hasValueInSearch ? 'vms-search-box__input--filled' : '',
      ]"
      @input="$emit('update:current-refinement', $event)"
    />
  </div>
</template>

<script>
import { sample, isEmpty } from 'lodash'

export default {
  props: {
    currentRefinement: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    hasValueInSearch() {
      return !isEmpty(this.currentRefinement)
    },

    searchPlaceholder() {
      const placeholdersArray = [
        'Search for something. Anything…',
        'Search for whatever; email, name, company…',
        'Type any contact details, we will do the rest!',
        'Who would you like to make a project for today?',
        'Search for whatever you want... No, not that!',
        'Search for someone… No, you can’t swipe right.',
        'Only know their first name? No probs, we got you',
        'Search for any name, company, office, or email.',
      ]

      return sample(placeholdersArray)
    },
  },

  methods: {
    handleSearchIconClick() {
      this.$refs.searchContactForNewProjectInput.focus()
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/search-box';
</style>
